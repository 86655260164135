.layout-wrapper{
  position: relative;
  .mud-tooltip{
    pointer-events: none !important;
  }
}
.layout {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  .layout_content{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    overflow: hidden;
  }

  main{
    flex-grow: 1;
    overflow: hidden;
  }
  
  header > div,
  main > div,
  header > div > div,
  main > div > div {
    height: 100%;
  }
}



//the device is small but no touch device
@media (max-width: 1279) and (hover: hover) {
}

//the device is very small or just small and a touch device
@media (max-width: 1279) and (hover: none), (max-width: 600px) {
  .layout {
    .layout_content{
      flex-direction: column-reverse;
    }
  }
}