html,
body {
  min-height: 100vh;
  min-height: 100dvh;
  min-width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  // user-select: none;
}

body {
  font-family: var(--text-default-family);  
  font-size: var(--text-default-size);
  font-weight: var(--text-default-weight);
  line-height: var(--text-default-lineheight); 
  letter-spacing: var(--text-default-letterspacing);

  transition: color var(--duration-500) var(--timing-inout),
  background-color var(--duration-500) var(--timing-inout);
  background: var(--color-body-background);  
  color: var(--color-text);
}