:root{
  --color-body-background: #ffffff;
  --color-body-text: #1a1a1a;
  --color-primary: #002c4d;
}
@media (prefers-color-scheme: dark) {
  :root{
    --color-body-background: #1b1a19ff;
    --color-body-text: #ffffff;
    --color-primary: #416988;
  }
}