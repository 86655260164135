.loading-animation-wrapper{
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;;
  background: var(--color-body-background);
  padding-bottom: 2vh;
}
.loading-animation {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-animation div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--color-primary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-animation div:nth-child(1) {
  left: 8px;
  animation: loading-animation1 0.6s infinite;
}
.loading-animation div:nth-child(2) {
  left: 8px;
  animation: loading-animation2 0.6s infinite;
}
.loading-animation div:nth-child(3) {
  left: 32px;
  animation: loading-animation2 0.6s infinite;
}
.loading-animation div:nth-child(4) {
  left: 56px;
  animation: loading-animation3 0.6s infinite;
}
@keyframes loading-animation1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-animation3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-animation2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
